import React, { useState, useEffect } from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useDispatch, useSelector } from 'react-redux';
import { setOneTrustAccepted, setOneTrustTargetingAccepted } from 'redux/ui/slice';
import {checkCookie} from 'hooks/CheckCookie';
import { getUrlCountryData } from 'utils/libraryUtils';
import { extractTaxonomyValues } from 'hooks/taxonomyGoogleAnalytics';
import { getValue } from 'utils/contentUtils';

export const TARGETING_COOKIE = 'C0004';

const OneTrustEventInteraction = ({ content }) => {
  const { cookieBannerVersion } = useWebsiteConfigObject();
  let cookieVersionValue = cookieBannerVersion?.selection;
  const { gaMeasurementId } = getUrlCountryData();

  const { taxonomyFields } = useSelector((state) => state.ga);
  const taxonomy = extractTaxonomyValues(taxonomyFields, getValue(content?.elements?.taxonomy));

  const dispatch = useDispatch();
  const [oneTrustLoaded, setOneTrustLoaded] = useState(false);
  const { trackEventCookies } = useGoogleAnalytics();
  
  const oneTrustAccept = () => {
    setTimeout(()=>{
      const isPerformanceAccepted = checkCookie();
      if(isPerformanceAccepted){
        window[`ga-disable-${gaMeasurementId}`] = false;
        dispatch(setOneTrustAccepted(true));
        trackEventCookies(window.location.pathname, document.title,'granted', taxonomy);
      } else {
        dispatch(setOneTrustAccepted(false));
        trackEventCookies(window.location.pathname, document.title,'denied', taxonomy);
      }

      const isTargetingAccepted = checkCookie(TARGETING_COOKIE);
      if(isTargetingAccepted) {
        dispatch(setOneTrustTargetingAccepted(true));
        trackEventCookies(window.location.pathname, document.title,'granted', taxonomy);
      } else {
        dispatch(setOneTrustTargetingAccepted(false));
        trackEventCookies(window.location.pathname, document.title,'denied', taxonomy);
      }
    },1000);
  }
  const checkOneTrustLoaded = setInterval(() => {
    let oneTrustCookieAccept = document.getElementById("onetrust-consent-sdk");
    if(oneTrustCookieAccept){
      setOneTrustLoaded(true);
      clearInterval(checkOneTrustLoaded);
    }
  },1000);

  if(cookieVersionValue != "OneTrust") {
    clearInterval(checkOneTrustLoaded);
  }
  useEffect(()=>{
    if(oneTrustLoaded && cookieVersionValue === "OneTrust") {
      setTimeout(()=>{
        const isPerformanceAccepted = checkCookie();
        if(isPerformanceAccepted){
          dispatch(setOneTrustAccepted(true));
        } else {
          dispatch(setOneTrustAccepted(false));
        }

        const isTargetingAccepted = checkCookie(TARGETING_COOKIE);
        if(isTargetingAccepted) {
          dispatch(setOneTrustTargetingAccepted(true));
        } else {
          dispatch(setOneTrustTargetingAccepted(false));
        }
      },1000);
    }
    if(oneTrustLoaded && cookieVersionValue === "OneTrust") {
      let oneTrustCookieAccept = document.getElementById("onetrust-accept-btn-handler");
      let oneTrustCookieAcceptRecommended = document.getElementById("accept-recommended-btn-handler");
      let oneTrustCookieSavePreference= document.getElementsByClassName("save-preference-btn-handler");
      let oneTrustCookieSavePreference1 = document.getElementsByClassName("ot-bnr-save-handler");
      let oneTrustRefuseAll = document.getElementsByClassName("ot-pc-refuse-all-handler");
      if(oneTrustCookieAccept){
        oneTrustCookieAccept.removeEventListener("click", oneTrustAccept);
        oneTrustCookieAccept.addEventListener("click", oneTrustAccept);
      }
    
      if(oneTrustCookieAcceptRecommended){
        oneTrustCookieAcceptRecommended.removeEventListener("click", oneTrustAccept);
        oneTrustCookieAcceptRecommended.addEventListener("click", oneTrustAccept);
      }
    
      if(oneTrustCookieSavePreference && oneTrustCookieSavePreference[0]){
        oneTrustCookieSavePreference[0].removeEventListener("click", oneTrustAccept);
        oneTrustCookieSavePreference[0].addEventListener("click", oneTrustAccept);
      }
    
      if(oneTrustCookieSavePreference1 && oneTrustCookieSavePreference1[0]){
        oneTrustCookieSavePreference1[0].removeEventListener("click", oneTrustAccept);
        oneTrustCookieSavePreference1[0].addEventListener("click", oneTrustAccept);
      }
      if(oneTrustRefuseAll && oneTrustRefuseAll[0]){
        oneTrustRefuseAll[0].removeEventListener("click", oneTrustAccept);
        oneTrustRefuseAll[0].addEventListener("click", oneTrustAccept);
      }
    }
  },[oneTrustLoaded])
  return null;
};

export default OneTrustEventInteraction;
