import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { checkCookie } from './CheckCookie';
import { useSelector } from 'react-redux';

const useSfmc = () => {
  const { cookieBannerVersion, oneTrustAnalyticsGroupId } =
    useWebsiteConfigObject();
  let cookieVersionValue = cookieBannerVersion?.selection;
  const cookieAcceptance = useContext(cookieConfirmationContext);
  const [initialized, setInitialized] = useState(false)
  const [analyticsCookiesAccepted, setAnalyticsCookiesAccepted] =
    useState(false);
  const { oneTrustAccepted, oneTrustTargetingAccepted } = useSelector(state => state.ui)

  const SalesforceInteractions = window.SalesforceInteractions;
  const consents =
    SalesforceInteractions && SalesforceInteractions.getConsents();

  const updateConsents = useCallback(() => {
    if (SalesforceInteractions) {
      if (Boolean(consents?.length)) {
        const updatedStatus = analyticsCookiesAccepted ? SalesforceInteractions.ConsentStatus.OptIn : SalesforceInteractions.ConsentStatus.OptOut;
        
        const consent = {
          provider: 'OneTrust',
          purpose: 'Performance',
          status: oneTrustAccepted ? SalesforceInteractions.ConsentStatus.OptIn : SalesforceInteractions.ConsentStatus.OptOut,
        };

        SalesforceInteractions.updateConsents(consent);

        SalesforceInteractions.updateConsents({
            ...consent,
            purpose: 'Personalization',
            status: updatedStatus
        });
      }
    }
  }, [SalesforceInteractions, analyticsCookiesAccepted, consents, oneTrustAccepted]);

  // const getCookie = checkCookie(oneTrustAnalyticsGroupId);

  useEffect(() => {
    let cookieCheck;
    // validate cookie for OptanonConsent
    if (cookieVersionValue === 'OneTrust') {
      cookieCheck = oneTrustTargetingAccepted;
    } else {
      cookieCheck = cookieAcceptance?.analyticsCookiesAccepted;
    }

    setAnalyticsCookiesAccepted(cookieCheck);
  }, [
    cookieAcceptance?.analyticsCookiesAccepted,
    cookieVersionValue,
    oneTrustTargetingAccepted,
    oneTrustAnalyticsGroupId,
  ]);

  useEffect(() => {
    if(initialized) updateConsents();
  }, [analyticsCookiesAccepted, oneTrustAccepted, initialized, updateConsents]);

  const init = useCallback(() => {
    if (SalesforceInteractions && !Boolean(consents?.length) && analyticsCookiesAccepted) {
      SalesforceInteractions.init({
        consents: [
          {
            provider: 'OneTrust',
            purpose: 'Performance',
            status: SalesforceInteractions.ConsentStatus.OptOut,
          },
          {
            provider: 'OneTrust',
            purpose: 'Personalization',
            status: SalesforceInteractions.ConsentStatus.OptOut,
          },
        ],
      })
      setInitialized(true)
    }
  }, [SalesforceInteractions, analyticsCookiesAccepted, consents])

  return {
    init,
  };
};

export default useSfmc;
