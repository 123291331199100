import { createSlice } from '@reduxjs/toolkit'

// Initial state
const initialState =  {
    headerHeightInfo: 0,
    serviceDisabled: false,
    errorMessage: "",
    subNavStatus: false,
    mobileNavStatus : false,
    userMenuStatus : false,
    libraryMenuStatus: false,
    encapsulationFeature: false,
    oneTrustAccepted: false,
    oneTrustTargetingAccepted: false,
    redesign: true,
    accessRules: [],
    navigation : {
        user : [],
        main : [],
        mobile: null,
    },
    countryLibrary: null,
    graphicalBanners: []
}

// Scroll Lock Class Name
const ScrollLockClass = 'Body--ScrollLock'

// Cookie Slice
const uiSlice = createSlice({
    name:'ui',
    initialState,
    reducers: {
        setServiceDisabled: (state) => {
            state.serviceDisabled = true
        },
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload
        },
        _setHeaderHeightInfo: (state, action) => {
            state.headerHeightInfo = action.payload
        },
        _setSubNavStatus: (state, action) => {
            state.subNavStatus = action.payload
        },
        setMobileNavStatus: (state, action) => {
            state.mobileNavStatus = action.payload
        },
        setUserMenuStatus: (state, action) => {
            state.userMenuStatus = action.payload
        },
        setLibraryMenuStatus: (state, action) => {
            state.libraryMenuStatus = action.payload
        },
        setEncapsulationFeature: (state, action) => {
            state.encapsulationFeature = action.payload
        },
        setOneTrustAccepted: (state, action) => {
            state.oneTrustAccepted = action.payload
        },
        setOneTrustTargetingAccepted: (state, action) => {
            state.oneTrustTargetingAccepted = action.payload
        },
        setAccessRules: (state, action) => {
            state.accessRules = action.payload || []
        },
        setRedesign: (state, action) => {
            state.redesign = action.payload || false
        },
        userNavigation : (state, action) => {
            state.navigation.user = action.payload
        },
        mainNavigation : (state, action) => {
            state.navigation.main = action.payload
        },
        mobileNavigation : (state, action) => {
            state.navigation.mobile = action.payload
        },
        setCountryLibrary : (state, action) => {
            state.countryLibrary = action.payload
        },
        setGraphicalBanners : (state, action) => {
            state.graphicalBanners = action.payload
        }
    }
})

// Export Action
export const { setMobileNavStatus, setUserMenuStatus, setLibraryMenuStatus, userNavigation, mainNavigation, mobileNavigation, _setHeaderHeightInfo, _setSubNavStatus, setEncapsulationFeature, setAccessRules, setServiceDisabled, setErrorMessage, setOneTrustAccepted, setOneTrustTargetingAccepted, setRedesign, setCountryLibrary, setGraphicalBanners } = uiSlice.actions

//#region Mobile Toggle

    // Toggle Mobile
    export const toggleMobileNav = () => async (dispatch, getState) => {
        document.body.classList.toggle(ScrollLockClass, !getState().ui.mobileNavStatus)
        dispatch(setUserMenuStatus(false)) // Close user menu
        dispatch(setMobileNavStatus(!getState().ui.mobileNavStatus))
    }

    // Close Mobile
    export const closeMobileNav = () => async (dispatch) => {
        document.body.classList.toggle(ScrollLockClass, false)
        dispatch(setMobileNavStatus(false))
    }

    export const setAuthEncapsulation = () => async (dispatch) => {
        dispatch(setEncapsulationFeature(true))
    }

    // Open Mobile
    export const openMobileNav = () => async (dispatch) => {
        document.body.classList.toggle(ScrollLockClass, true)
        dispatch(setUserMenuStatus(false)) // Close user menu
        dispatch(setMobileNavStatus(true))
    }

//#endregion

//#region User Menu Toggle

    // Toggle Mobile
    export const toggleUserMenu = () => async (dispatch, getState) => {
        dispatch(setMobileNavStatus(false)) // Close Mobile Navigation
        dispatch(setUserMenuStatus(!getState().ui.userMenuStatus))
    }

    // Close Mobile
    export const closeUserMenu = () => async (dispatch) => {
        dispatch(setUserMenuStatus(false))
    }

    // Open Mobile
    export const openUserMenu = () => async (dispatch) => {
        dispatch(setMobileNavStatus(false)) // Close Mobile Navigation
        dispatch(setUserMenuStatus(true))
    }

//#endregion

//#region Library Menu Toggle

    // Toggle Library
    export const toggleLibraryMenu = () => async (dispatch, getState) => {
        window.scrollTo(0, 0)
        document.body.classList.toggle(ScrollLockClass, !getState().ui.libraryMenuStatus)
        dispatch(setLibraryMenuStatus(!getState().ui.libraryMenuStatus))
    }

    // Close Library
    export const closeLibraryMenu = () => async (dispatch) => {
        window.scrollTo(0, 0)
        document.body.classList.toggle(ScrollLockClass, false)
        dispatch(setLibraryMenuStatus(false))
    }

    // Open Library
    export const openLibraryMenu = () => async (dispatch) => {
        window.scrollTo(0, 0)
        document.body.classList.toggle(ScrollLockClass, true)
        dispatch(setLibraryMenuStatus(true))
    }

//#endregion

//#region Navigation

    // User Navigation
    export const setUserNavigation = data => async (dispatch) => {
        dispatch(userNavigation(data))
    }

    // Main Navigation
    export const setMainNavigation = data => async (dispatch) => {
        dispatch(mainNavigation(data))
    }

    export const setMobileNavigation = data => async (dispatch) => {
        dispatch(mobileNavigation(data))
    }

//#endregion

//#region Header
    // Set Header Info
    export const setHeaderHeightInfo = data => async (dispatch) => {
        dispatch(_setHeaderHeightInfo(data))
    }
//#endregion

//#region SubNav
    export const setSubNavStatus = data => async (dispatch) => {
        dispatch(_setSubNavStatus(data))
    }
//#endregion

// Export Reducer
export default uiSlice.reducer