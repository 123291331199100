import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import styleCSS from './videochapters.module.scss';
import KKLogo from 'assets/images/kk-logo.svg';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { getAssetUrl } from 'api/acousticApi';
import './tooltip.scss';
import Image from 'components/reusableComponents/image/Image';
import "../Video.scss";
import { PlayVideo } from 'components/shared/SvgIcons';
import {useLocationSearchParameter} from 'utils/urlUtils';
import {getFileName} from 'utils/stringUtils';

//convert time code to seconds, format minutes:seconds.milliseconds or hours:minutes:seconds.milliseconds
const convertTimeToSeconds = (time) => {

  if (!time) return null;

  const timePattern = /^([0-9]{1,2}:)?([0-9]{1,2})(:([0-9]{1,2})(\.([0-9]{1,3})))$/;
  const matches = time.match(timePattern);
  if (!matches) return null;

  let timeArray = time.split(':');
  let seconds = 0;
  if (timeArray.length === 3) {
    seconds = parseInt(timeArray[0]) * 3600 + parseInt(timeArray[1]) * 60 + parseFloat(timeArray[2]);
  } else if (timeArray.length === 2) {
    seconds = parseInt(timeArray[0]) * 60 + parseFloat(timeArray[1]);
  }

  return seconds;
}

const VideoWithChapters = ({
  url,
  videoAsset,
  onPause,
  onEnded,
  onSeeking,
  onSeeked,
  onTimeUpdate,
  title,
  webvttChaptersUrl,
  subtitles,
  crossOrigin,
  style,
  videoPoster,
  videoPosterImage,
  showControls,
  disablePlay,
  externalVideo
}) => {
  const { trackVideo, trackVideoChapter } = useGoogleAnalytics();
  const [hidePoster, setHidePoster] = useState(false);
  const videoRef = useRef(null);
  const externalFileVideo = getFileName(externalVideo?.value);

  let videoTimeCode = useLocationSearchParameter('videoTimeCode');
  let videoChapterName = useLocationSearchParameter('videoChapter');
  let fileName = useLocationSearchParameter('videoName');

  let timeCode = null;
  if ((videoChapterName || videoTimeCode)) {
    if (videoRef.current) {

      if (videoChapterName) {
        let decodedVideoChapterName = decodeURIComponent(videoChapterName);
        let chapters = videoRef.current?.textTracks[0]?.cues;
        if (chapters) {
          for (let i = 0; i < chapters.length; i++) {
            if (chapters[i].text === decodedVideoChapterName) {
              timeCode = chapters[i].startTime;
              break;
            }
          }
        }
      } else if (videoTimeCode) {
        timeCode = videoTimeCode ? convertTimeToSeconds(videoTimeCode) : null;
      }
    }

  }

  useEffect(() => {

    if (videoRef.current) {
      if (fileName) {
        let decodedFileName = decodeURIComponent(fileName);
        const file = externalFileVideo ? decodeURIComponent(externalFileVideo) : videoAsset.fileName;
        if (file === decodedFileName && timeCode) {
          videoRef.current.currentTime = timeCode;
        }
      }else if (timeCode) {
        videoRef.current.currentTime = timeCode;
      }
    }
  } , [fileName, timeCode, videoAsset?.fileName, videoRef?.current?.readyState]);

  const playVideo = () => {
    if(!disablePlay && videoRef.current){
      videoRef.current.play();
    }
  }

  let playTimes = 0;
  function onPlay(e) {
    const videoElements = document.getElementsByTagName('video');
    Array.from(videoElements).forEach(video => {
      if (video !== e.target && !video.paused) video.pause();
    });
    setHidePoster(true);
    playTimes = playTimes + 1;
    const loadedPercentage = Math.round(
      (e.currentTarget.currentTime / e.currentTarget.duration) * 100
    );

    trackVideo(!hidePoster && playTimes === 1 ? 'video_start' : 'video_resume', e?.currentTarget, loadedPercentage);
  }

  function displayChapters(e) {
    const videoElement = e.target;

    function hideControls() {
      if (videoElement.paused) return;
      videoElement.parentElement.querySelector('.controls').style.display =
        'none';
    }

    videoElement.addEventListener(
      'mouseover',
      function () {
        videoElement.parentElement.querySelector('.controls').style.display =
          'block';
          // videoElement.removeAttribute("title");
        //TODO: Fix a bug with mouse over after a while and video is playing
        // setTimeout(function () {
        //   hideControls();
        // }, 3000);
      },
      false
    );

    videoElement.addEventListener(
      'mouseleave',
      function (e) {
        videoElement.setAttribute("title", title?.value ? title.value : (externalVideo?.value ? externalFileVideo : videoAsset.fileName));
        if (e?.relatedTarget?.localName === 'li') return;
        hideControls();
      },
      false
    );

    const textTrack = e.target.querySelector('track').track;
    if (
      e.target.querySelector('track').readyState === 2
    ) {
      if (textTrack.kind === 'chapters') {
        textTrack.mode = 'hidden';
        for (var i = 0; i < textTrack.cues.length; ++i) {
          const liElement = document.createElement('li');
          const aElement = document.createElement('a');
          const liMarker = document.createElement('li');
          const spanTooltip = document.createElement('span');
          const chapterName = textTrack.cues[i].text;
          const previousChapterName = i > 0 ? textTrack.cues[i - 1].text : '';

          liMarker.classList.add('tooltip');
          spanTooltip.classList.add('tooltiptext');
          spanTooltip.classList.add('tooltip-top');

          spanTooltip.innerHTML = chapterName;

          const percentage =
            (textTrack.cues[i].startTime / videoElement.duration) * 100;
          liMarker.style.marginLeft = percentage + '%';

          liMarker.setAttribute('value', textTrack.cues[i].startTime);
          aElement.setAttribute('id', textTrack.cues[i].startTime);
          aElement.setAttribute('tabindex', '0');

          aElement.appendChild(document.createTextNode(chapterName));
          liElement.appendChild(aElement);
          liMarker.appendChild(spanTooltip);
          if (e.target.parentElement
            .querySelector('.chapterList').childNodes.length != textTrack.cues.length) {
              e.target.parentElement
              .querySelector('.chapterList')
              .appendChild(liElement);
          }
          videoElement.parentElement
            .querySelector('.controls')
            .appendChild(liMarker);
          aElement.addEventListener(
            'click',
            function () {
              trackVideoChapter('video_chapter_click', videoElement, previousChapterName, chapterName, percentage);
              videoElement.currentTime = this.id;
            },
            false
          );
          liMarker.addEventListener(
            'click',
            function () {
              trackVideoChapter('video_chapter_click', videoElement, previousChapterName, chapterName, percentage);
              videoElement.currentTime = this.value;
            },
            false
          );
        }
        textTrack.addEventListener(
          'cuechange',
          function () {
            const currentLocation = this.activeCues[this.activeCues.length - 1]
              ?.startTime;
            const chapter = document.getElementById(currentLocation);
            if (chapter) {
              const locations = [].slice.call(
                document.querySelectorAll('.chapterList li a')
              );
              for (var i = 0; i < locations.length; ++i) {
                locations[i].classList.remove(styleCSS.current);
              }
              chapter.classList.add(styleCSS.current);
            }
          },
          false
        );
      }
    }
  }

  const webvttSubtitles = [];
  (subtitles?.values || []).forEach((element) => {
    webvttSubtitles.push(
      <track
        label={element.elements.label.value}
        kind='subtitles'
        srclang={element.elements.srclang.value}
        src={getAssetUrl(element.elements.webvttSubtitleUrl)}
        default={element.elements.default.value}
      ></track>
    );
  });

  let posterImage = getAssetUrl(videoPosterImage);
  posterImage = posterImage ? posterImage : KKLogo;

  return (
    <figure className={`${styleCSS.videotrackcontainer} video video--chapters`} style={style}>
      {videoPosterImage?.url && videoPoster?.value && !hidePoster &&
          <div className='video__poster' onClick={playVideo}>
          <Image className="video__poster__img" data={videoPosterImage} />
          <PlayVideo className="video__poster__play"/>
        </div>
      }
      {
        externalVideo?.value ? 
          <video
            ref={videoRef}
            controls={showControls}
            controlsList='nodownload'
            onPause={onPause}
            onPlay={onPlay}
            onEnded={onEnded}
            onTimeUpdate={onTimeUpdate}
            onSeeking={onSeeking}
            onSeeked={onSeeked}
            key={url}
            onCanPlay={displayChapters}
            crossOrigin={crossOrigin}
            style={{ width: '75%', height:'100%', backgroundColor:!videoPosterImage?.url && videoPoster?.value ? "white" : ""}}
            poster={!videoPosterImage?.url && videoPoster?.value ? KKLogo : ""}
            title={externalFileVideo}
          >
            <source src={url} type='video/mp4' />
            <track kind='chapters' src={webvttChaptersUrl} default />
            {webvttSubtitles}
          </video>
          :
          <video
            ref={videoRef}
            controls={showControls}
            controlsList='nodownload'
            onPause={onPause}
            onPlay={onPlay}
            onEnded={onEnded}
            onTimeUpdate={onTimeUpdate}
            onSeeking={onSeeking}
            onSeeked={onSeeked}
            key={url}
            crossOrigin={crossOrigin}
            onCanPlay={displayChapters}
            style={{ width: '75%', height:'100%', backgroundColor:!videoPosterImage?.url && videoPoster?.value ? "white" : ""}}
            poster={!videoPosterImage?.url && videoPoster?.value ? KKLogo : ""}
            title={title?.value ? title.value : videoAsset.fileName}
            id={videoAsset.id}
          >
            <source src={url} type={videoAsset.mediaType} />
            <track kind='chapters' src={webvttChaptersUrl} default />
            {webvttSubtitles}
          </video>
      }
      
      <ul className='controls' data-state='hidden'></ul>
      {webvttChaptersUrl && (
        <figcaption role='menu' aria-label='Message Log'>
          <ol className='chapterList'></ol>
        </figcaption>
      )}
    </figure>
  );
};

VideoWithChapters.propTypes = {
  element: PropTypes.objectOf(PropTypes.any),
};

export default VideoWithChapters;
