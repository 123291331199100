import React from 'react'
import { contentElementProptype } from 'propTypes/contentProptypes'
import { getValue } from 'utils/contentUtils'

// components
import RichText from 'components/reusableComponents/richText/RichText'
import Video from 'components/reusableComponents/video/Video'
import Image from 'components/reusableComponents/image/Image'

// styles
import './Header.scss';

const HeaderMedia = ({ video, image,externalVideoLink, videoPoster }) => {
    if ((video && video.url) || externalVideoLink?.value) {
        return (
            <div className='header__media-container'>
                <Video element={video} externalVideo={externalVideoLink} videoPoster={videoPoster} />
            </div>
        )
    }
    if (image && image.url) {
        return (
            <div className='header__media-container'>
                <figure className='header__figure'>
                    <Image data={image} />
                </figure>
            </div>
        )
    }
    return null
}

HeaderMedia.propTypes = {
    headerImage: contentElementProptype,
    headerVideo: contentElementProptype,
}

const Header = ({
    title,
    titleRichText,
    content,
    backgroundColour
 }) => {
    if (!content?.value?.elements) { return null }
    const { subtitle, text, video,externalVideoLink, videoPoster, image } = content?.value?.elements || {}
    const textValue = getValue(text);
    const headerColumnStyle = textValue?.text?.value && 'header__column';

    // over-ride the default KKI font colours with white if a custom background colour has been selected (on brand pages) - so far all brands have white 
    // text in the header. A custom font colour may have to be specified in the Module - Header in the future but for now white works for all brands
    const fontColour = backgroundColour?.value ? '#FFFFFF' : ''
    const style = {
        backgroundColor: `${backgroundColour?.value}`
    };

    const fontStyle = {
        color: `${fontColour}`
    };

    return(
        <div className='header__outer' style={style}>
            <div className='container'>
                <div className='header__inner'>
                    {!video?.url && !image?.url && !externalVideoLink?.value ? (
                        <>
                            <div className={`${headerColumnStyle} header__column--title`}>
                                { subtitle?.value && (
                                    <h2 className='header__subtitle' style={fontStyle}>{subtitle?.value}</h2>
                                )}
                                {
                                    titleRichText?.value?.text?.value != '' && titleRichText?.value?.text?.value != undefined ?
                                    <RichText data={titleRichText?.value?.text} className='header__title' style={fontStyle} /> :
                                    <h1 className='header__title' style={fontStyle}>{title?.value}</h1>
                                }
                            </div>
                            { textValue && (
                                <div className={`${headerColumnStyle}`}>
                                    <div className='header__text' style={fontStyle}><RichText data={textValue.text}/></div>
                                </div>
                            )}
                        </>
                        )
                        :
                        <>
                            <div className='header__column'>
                                { title?.value && (
                                    <h1 className='header__title' style={fontStyle}>{title?.value}</h1>
                                )}
                                { subtitle?.value && (
                                    <h3 className='header__subtitle' style={fontStyle}>{subtitle?.value}</h3>
                                )}
                                { text?.value && (
                                    <div className='header__text' style={fontStyle}><RichText data={text?.value?.text}/></div>
                                )}
                            </div>
                            <div className='header__column'>
                                <HeaderMedia video={video} externalVideoLink={externalVideoLink} videoPoster={videoPoster} image={image} />
                            </div>
                        </>
                    }                          
                </div>
            </div>           
        </div>
    )
}


export default Header