import { AppConfig } from 'AppConfig';

const {
  domain,
  domainPreview,
  apiPath,
  contentHubId,
  deliveryPath,
  renderContextPath,
  searchPath,
  previewMode,
  loginBasicAuth,
} = AppConfig.acousticApi;

const DELIVERY_URL = `${domain}/${apiPath}/${contentHubId}/${deliveryPath}`;
const RENDER_CONTEXT_URL = (previewMode ? `${domainPreview}` : `${domain}`) + `/${apiPath}/${contentHubId}/${deliveryPath}/${renderContextPath}`;
const SEARCH_URL = `${DELIVERY_URL}/${searchPath}`;
const SEARCH_URL_RENDER = `${DELIVERY_URL}/rendering/${searchPath}`;
const PREVIEW_MODE = previewMode;

const BASIC_AUTH_GET_URL = `${domain}/${apiPath}/${contentHubId}/${loginBasicAuth}`;
const BASIC_AUTH_GET_PREVIEW_URL = `${domainPreview}/${apiPath}/${contentHubId}/${loginBasicAuth}`;

export {
  domain,
  DELIVERY_URL,
  RENDER_CONTEXT_URL,
  BASIC_AUTH_GET_URL,
  BASIC_AUTH_GET_PREVIEW_URL,
  PREVIEW_MODE,
  SEARCH_URL,
  SEARCH_URL_RENDER,
};
