import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { getPageTemplateForType } from "components/pageTemplates/pageTemplateTypes";
import { LOGIN_PAGE_PATH } from "routes/routeConstants";
import { PayWall } from "components/layout/payWall/PayWall";
import ContentGate from "modules/contentGate/ContentGate";
import { ContentGateWrapper } from "components/layout/ContentGateWrapperAnonymous";
import { Features } from "components/shared/feature/Features";
import { Loader, LARGE_LOADER } from 'components/shared/Loader';
import { userActions } from "redux/user/slice";
import { ContentGateWrapperAnonymous } from "components/layout/ContentGateWrapperAnonymous";
import { getUrlCountryPath } from 'utils/urlUtils';
import { useWebsiteConfigObject } from "websiteConfig/useWebsiteConfig";
import { authActions, setTaxonomyRegistration } from 'redux/authentication/slice';
import { useCookies } from 'react-cookie';
import useVeevaUserAccess, { VEEVA_SRC } from 'hooks/useVeevaUserAccess';
import { AppConfig } from 'AppConfig';
import useTaxonomyRegistration from "hooks/useTaxonomyRegistration";
import { extractTaxonomyValues } from "hooks/taxonomyGoogleAnalytics";

// component representing a page whos content is retrieved from the acoustic api
// accepts the contentId and templateType as props
// TODO: the templateType might be better retrieved from the content itself if for whatever reason
// we can't go through the router
const KKICMS = ({ content, templateType, path, promotional }) => {
    const dispatch = useDispatch();

    const { status, data } = useSelector((state) => state.authentication)
    const { veevaUser, veevaUserInvalid} = useSelector((state) => state.authentication.status);
    const userData = data?.user;

    const loggedIn = status?.loggedIn

    const acceptedPromtionalConsent = data?.consent?.acceptedPromotional

    const PageTemplate = templateType ? getPageTemplateForType(templateType) : null

    const accessLevel = (content && content.elements && content.elements.contentAccess && content.elements.contentAccess.value && content.elements.contentAccess.value.selection) || null

    const relatedPageUrl = (content && content.elements && content.elements.relatedPageUrl && content.elements.relatedPageUrl.value) || ''
    const { secureContentGate } = useWebsiteConfigObject()
    // redirect to login page if access level C and the user is not logged in
    const requiresLogin = accessLevel === 'c'

    const { promotionalWebinar } = content?.elements || null;
    const pageTitle = content?.elements?.title?.value || content?.elements?.title || null;
    const DAYS_COOKIES_VALID_FOR_REFUSAL = 3;
    const daysInSeconds = (days) => days * 86400;

    const COOKIE_OPTIONS = {
    path: `/${getUrlCountryPath()}`,
    maxAge: daysInSeconds(DAYS_COOKIES_VALID_FOR_REFUSAL),
    };

    const [cookies, setCookie] = useCookies([
        'userSelfCertified',
    ]);

    if (!cookies?.userSelfCertified) {
        setCookie('userSelfCertified', false, { ...COOKIE_OPTIONS, path: '/' });
        dispatch(authActions.kki.guestLogout());
    }

    if (cookies?.userSelfCertified === "false") {
        dispatch(authActions.kki.guestLogout());
    }

    useEffect(
      () => dispatch(userActions.global.details.fetch()) , 
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    
    function checkMainRequiredData(data) {
      let allDataAvailable = true;
      if (
        data?.firstName === null ||
        data?.lastName === null ||
        data?.email === null ||
        data?.firstName === '' ||
        data?.lastName === '' ||
        data?.email === ''
      ) {
        allDataAvailable = false;
      }
  
      return allDataAvailable;
    }
    
  function checkOptionMarketing(data){
        return data?.promotionalMaterialsOptIn;
  }   
  
  function checkOptionMarketing(data) {
    return data?.promotionalMaterialsOptIn;
  }  
  
  const urlParams = new URLSearchParams(window.location.search);
  let userId = urlParams.get(AppConfig.veevaApi.userIdQueryName) ?? urlParams.get('actid');
  userId = userId ?? urlParams.get('actId');

  // Veeva users session management
  const veevaAccessGranted = useVeevaUserAccess(accessLevel);

  if((userId || veevaUser === true) && veevaUserInvalid){
    return (
      <Redirect
        to={{
          pathname: "/",
          // state provided in the `to` field object is accessible in the redirected
          // route as props.location.state. Using this to provide a return path
          // for after successful login
          // https://reactrouter.com/web/api/Redirect/to-object
          state: { returnPath: path },
        }}
      />
    );
  }
  if(veevaUser === true || (veevaUser === null && userId)) {
    return (
      <section role='main' className='loading-page'>
        <Loader className='center-aligned-loader' size={LARGE_LOADER} />
      </section>
    );
  }

  const crawlable = content?.elements?.basePage?.value?.crawlable?.value;
  if(veevaAccessGranted || (status.bot && crawlable)){
    return <PageTemplate content={content} template={templateType} />;
  }

  if(requiresLogin && !loggedIn && !veevaUser) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_PAGE_PATH,
          // state provided in the `to` field object is accessible in the redirected
          // route as props.location.state. Using this to provide a return path
          // for after successful login
          // https://reactrouter.com/web/api/Redirect/to-object
          state: { returnPath: path, requiresLogin, taxonomy: content?.elements?.taxonomy?.value },
        }}
      />
    );
  } 

  if (!loggedIn && accessLevel === "b" && !status.guest && cookies.userSelfCertified === "false") {
    return (
      <PayWall
        pageTitle={pageTitle}
        loggedIn={loggedIn}
        accessLevel={accessLevel}
        relatedPageUrl={relatedPageUrl}
        content={content}
      />
    );
  }
  
  //check access level c pages
  let baseUserData = checkMainRequiredData(userData);
  let optionMarketing = checkOptionMarketing(userData);
  if (loggedIn && accessLevel === 'c' && (!baseUserData || !optionMarketing)) {
    return (
      <>
        <Features pageName="" contentType="feature-anonymous-flow">
          {/* {!baseUserData && */}
          <ContentGateWrapperAnonymous
            secure={secureContentGate || false}
            optionMarketing={optionMarketing}
            baseInformation={baseUserData}
            pageTitle={pageTitle}
            content={content}
          ></ContentGateWrapperAnonymous>
          {/* } */}
        </Features>
        <PageTemplate content={content} template={templateType} />;
      </>
    );
  }

  if ((promotional || promotionalWebinar?.value) && !acceptedPromtionalConsent) {
    return <ContentGate pageTitle={pageTitle} type="promotional" />;
  }
  return <PageTemplate content={content} template={templateType} />;
};

KKICMS.propTypes = {
  content: PropTypes.objectOf(PropTypes.any),
  templateType: PropTypes.string,
  path: PropTypes.string,
};

export { KKICMS };
