import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { getUrlCountryData } from 'utils/libraryUtils';
import { useWebsiteConfigObject } from 'websiteConfig/useWebsiteConfig';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { cookieConfirmationContext } from 'components/layout/cookieBanner/CookieConfirmationContext';
import { useSelector } from 'react-redux';

const GTag = () => {
  const websiteConfig = useWebsiteConfigObject();
  // const cookieAcceptance = useContext(cookieConfirmationContext);
  const { oneTrustAccepted, oneTrustTargetingAccepted } = useSelector(state => state.ui)
  const { initialiseAnalytics } = useGoogleAnalytics();

  const cookieVersionValue = websiteConfig?.cookieBannerVersion?.selection;
  const groupId = websiteConfig?.oneTrustAnalyticsGroupId || 'C0002';
  const { gaMeasurementId, gtmMeasurementId } = getUrlCountryData();
  // const url = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`;
  const gtmSrc = `https://www.googletagmanager.com/gtm.js?id=${gtmMeasurementId}`;

  const loadScript = () => {
    const gtmId = 'gtm-script';
    let gtmScript = document.getElementById(gtmId);

    if(gtmScript) gtmScript.remove();

    gtmScript = document.createElement('script');
    gtmScript.id = gtmId
    gtmScript.src = gtmSrc;
    gtmScript.type = 'text/plain';

    if (cookieVersionValue && cookieVersionValue === 'OneTrust') {
      if(oneTrustAccepted || oneTrustTargetingAccepted) {
        gtmScript.type = 'text/javascript';
      } 
    }
    gtmScript.className = `optanon-category-${groupId}`;

    setTimeout(() => {
      document.head.appendChild(gtmScript);
    }, 500)
  }

  // useEffect(() => {
  //   loadScript();
  // }, []);

  useEffect(() => {
    if(oneTrustAccepted || oneTrustTargetingAccepted) {
      initialiseAnalytics()
    }

    loadScript();
  }, [oneTrustAccepted, oneTrustTargetingAccepted])
  

  return null;
};

export default GTag;
