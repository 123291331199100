import React from 'react';
import { getFooterTextDisplayData, getHrefs } from 'utils/contentUtils';
import RichText from 'components/reusableComponents/richText/RichText';
import { getImgAttr } from 'api/acousticApi';
import { TextCardLearnMore } from 'components/content/cards/TextCardLearnMore';
import Link from 'components/reusableComponents/link/Link';
import { useSelector } from 'react-redux';

import './HomepageCard.scss';

const HomepageCard = ({ element }) => {
  const {
    linkToInternalPageOrExternalLink,
    page,
    externalLink,
    title,
    icon,
    bodyCopy,
    displayLearnMoreOrFooterText,
    displayExternalLinkChevron,
    footerText,
  } = element.elements;

  const { src, alt } = getImgAttr(icon);
  const { redesign } = useSelector(state => state.ui);

  const { footerTextValue, displayChevron } = getFooterTextDisplayData({
    displayLearnMoreOrFooterText,
    displayExternalLinkChevron,
    footerText,
  });

  const { internalHref, externalHref } = getHrefs({
    externalLink,
    page,
    linkToInternalPageOrExternalLink,
  });

  const titleValue = title && title.value;
  const iconValue = src;

  const renderTitle = titleValue || iconValue;
  if(redesign){
    return (
      <>
        <div className='text-card--hp-carousel-hover'>
          
        </div>
        <Link
          className='homepage-card text-card text-card--hp-carousel'
          href={internalHref || externalHref || '/'}
        >
          {renderTitle && (
            <div className='homepage-card-header'>
              {iconValue && (
                <div className='homepage-card-icon'>
                  <img src={src} alt={alt} height='23' width='23' />
                </div>
              )}
              {titleValue && <h2 className='homepage-card-category'>{titleValue}</h2>}
            </div>
          )}
          {titleValue && <div className='homepage-card-title'><h2>{titleValue}</h2></div>}
          {bodyCopy && <RichText data={bodyCopy} />}
          {displayChevron ? (
            <TextCardLearnMore text={footerTextValue} />
          ) : (
            <p className='meta-tags'>{footerTextValue}</p>
          )}
        </Link>
      </>
    );
  }
  return (
    <Link
      className='text-card homepage-card'
      href={internalHref || externalHref || '/'}
    >
      {renderTitle && (
        <div className='homepage-card-header'>
          {titleValue && <h2 className='homepage-card-title'>{titleValue}</h2>}
          {iconValue && (
            <div className='homepage-card-icon'>
              <img src={src} alt={alt} height='45' width='45' />
            </div>
          )}
        </div>
      )}
      {bodyCopy && <RichText data={bodyCopy} />}
      {displayChevron ? (
        <TextCardLearnMore text={footerTextValue} />
      ) : (
        <p className='meta-tags'>{footerTextValue}</p>
      )}
    </Link>
  );
};

export { HomepageCard };
